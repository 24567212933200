"use client";

import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import Link from "next/link";

const locations = [
  {
    country: "INDIA",
    cities: [
      {
        name: "Shopware Development Company in Mumbai",
        link: "/locations/ecommerce-website-development-company-in-mumbai",
      },
      {
        name: "Web Design Andheri West",
        link: "/locations/web-design-company-in-andheri-west",
      },
      {
        name: "Web Design Malad",
        link: "/locations/malad-web-designers-and-developers",
      },
      { name: "Web Design Mumbai", link: "/locations/web-design-in-mumbai" },
      {
        name: "Web Design Versova",
        link: "/locations/web-design-services-in-versova",
      },
      { name: "SEO Expert in Mumbai", link: "/locations/seo-expert-in-mumbai" },
      {
        name: "Website Design Juhu Mumbai",
        link: "/locations/website-design-services-in-juhu-mumbai",
      },
      {
        name: "Website Design Lokhandwala",
        link: "/locations/website-design-lokhandwala",
      },
      { name: "Website Design India", link: "/locations/website-design-india" },
    ],
  },
  {
    country: "CANADA",
    cities: [
      {
        name: "Web Design Services in Canada",
        link: "/locations/website-maintenance-services-in-canada",
      },
      {
        name: "Web Design Toronto",
        link: "/locations/non-profit-website-designer-toronto",
      },
      {
        name: "Website Design Calgary",
        link: "/locations/website-design-services-in-calgary",
      },
      {
        name: "Website Design Oakville",
        link: "/locations/website-design-services-in-oakville",
      },
    ],
  },
  {
    country: "USA",
    cities: [
      {
        name: "Affordable Web Design Services Newcastle",
        link: "/locations/affordable-web-design-services-newcastle",
      },
      {
        name: "Atlanta Web Design and Development",
        link: "/locations/atlanta-web-design-and-development",
      },
      {
        name: "Corpus Christi Web Design Services",
        link: "/locations/corpus-christi-web-design-services",
      },
      { name: "Denver Web Design", link: "/locations/denver-web-design" },
      {
        name: "Digital Marketing Services for Small Businesses in North Carolina",
        link: "/locations/digital-marketing-services-for-small-businesses-in-north-carolina-usa",
      },
      { name: "Las Vegas Web Design", link: "/locations/las-vegas-web-design" },
      {
        name: "Los Angeles Web Design and Development",
        link: "/locations/los-angeles-web-design-and-development",
      },
      {
        name: "Minneapolis Web Design",
        link: "/locations/minneapolis-web-design",
      },
      { name: "Nashville Web Design", link: "/locations/nashville-web-design" },
      {
        name: "New Jersey Web Design and Development",
        link: "/locations/new-jersey-web-design-and-development",
      },
      {
        name: "Next.js and React Website Services in Delaware",
        link: "/locations/nextjs-and-react-website-services-in-delaware",
      },
      { name: "Ojai Web Design", link: "/locations/ojai-web-design" },
      {
        name: "Orange County Web Design",
        link: "/locations/orange-county-web-design",
      },
      { name: "Orlando Web Design", link: "/locations/orlando-web-design" },
      {
        name: "Pittsburgh Web Design",
        link: "/locations/pittsburgh-pa-web-design",
      },
      {
        name: "Sacramento Web Design",
        link: "/locations/sacramento-web-design",
      },
      { name: "San Diego Web Design", link: "/locations/san-diego-web-design" },
      {
        name: "Texas Website Design Services",
        link: "/locations/texas-website-design-services",
      },
      {
        name: "Tulsa Web Design and Development",
        link: "/locations/tulsa-web-design-and-development",
      },
      {
        name: "Watersedge Web Design",
        link: "/locations/watersedge-web-design",
      },
      {
        name: "Web Design Austin TX",
        link: "/locations/web-design-company-austin-tx",
      },
      {
        name: "Web Design Berkeley",
        link: "/locations/web-design-services-berkeley",
      },
      {
        name: "Web Design Fort Lauderdale",
        link: "/locations/web-design-fort-lauderdale",
      },
      {
        name: "Web Design Houston TX",
        link: "/locations/web-design-houston-tx",
      },
      {
        name: "Web Design Jacksonville",
        link: "/locations/web-design-jacksonville",
      },
      {
        name: "Web Design Raleigh",
        link: "/locations/web-page-design-raleigh",
      },
    ],
  },
  {
    country: "UK",
    cities: [
      { name: "Web Design Banbury", link: "/locations/web-design-banbury" },
      { name: "Web Design Blackpool", link: "/locations/web-design-blackpool" },
      {
        name: "Web Design Bournemouth",
        link: "/locations/web-design-bournemouth",
      },
      {
        name: "Web Design Dudley",
        link: "/locations/dudley-web-design-company",
      },
      { name: "Web Design Essex", link: "/locations/web-design-essex" },
      { name: "Web Design Glasgow", link: "/locations/web-design-glasgow" },
      { name: "Web Design Hampshire", link: "/locations/web-design-hampshire" },
      { name: "Web Design Leeds", link: "/locations/web-design-leeds" },
      {
        name: "Web Design London",
        link: "/locations/london-web-design-and-development",
      },
      { name: "Web Design Rochdale", link: "/locations/web-design-rochdale" },
    ],
  },
  {
    country: "AUSTRALIA",
    cities: [
      {
        name: "Web Design Canberra",
        link: "/locations/canberra-web-design-and-development",
      },
      {
        name: "Web Design Central Coast",
        link: "/locations/website-design-services-in-central-coast",
      },
      { name: "Web Design Sooke", link: "/locations/web-design-agency-sooke" },
    ],
  },
  {
    country: "OTHER",
    cities: [
      {
        name: "Web Design Singapore",
        link: "/locations/web-design-services-singapore",
      },
    ],
  },
];

const LocationSection = () => {
  const [openCountry, setOpenCountry] = useState(null);

  const toggleAccordion = (index) => {
    setOpenCountry(openCountry === index ? null : index);
  };

  return (
    <section className="bg-gray-50 py-16">
      <div className="max-w-screen-xl mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Content */}
        <div className="text-center md:text-left">
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-6">
            Explore Our Locations
          </h2>
          <p className="text-gray-600 text-lg">
            We proudly serve clients in major cities worldwide, 
            delivering exceptional web design, development, and digital 
            marketing services tailored to your needs. In India, our 
            services are highly sought after in <strong>Mumbai</strong>, where 
            we specialize in creating cutting-edge websites and ecommerce platforms. Expanding our reach globally, we also operate in cities like <strong>Toronto, London, San Diego, and Sydney</strong>, providing innovative digital solutions to businesses of all sizes. Whether you're looking for a bespoke web design in <strong>Mumbai</strong> or advanced SEO services in San Diego, our expert team is ready to help you achieve your digital goals with excellence and precision.
          </p>
        </div>

        {/* Right Accordion */}
        <div>
          {locations.map((location, index) => (
            <div
              key={index}
              className="border rounded-lg mb-4 overflow-hidden shadow"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center bg-white px-6 py-4 text-lg font-semibold text-gray-800 hover:bg-gray-100 focus:outline-none"
              >
                {location.country}
                <FaChevronDown
                  className={`transition-transform duration-300 ${
                    openCountry === index ? "rotate-180" : "rotate-0"
                  }`}
                />
              </button>
              {openCountry === index && (
                <div className="bg-gray-100">
                  {location.cities.map((city, cityIndex) => (
                    <Link
                      key={cityIndex}
                      href={city.link}
                      className="block px-6 py-3 text-gray-600 hover:text-gray-800 hover:bg-gray-200"
                    >
                      {city.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LocationSection;
