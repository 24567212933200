"use client";

import React, { useState } from "react";
import Link from "next/link";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";


const services = {
  "Web Design": [
    { title: "Affordable Web Design", url: "affordable-web-design" },
    {
      title: "AI Powered Website Design and Development",
      url: "ai-powered-website-design-and-development",
    },
    { title: "Bespoke Website Design", url: "bespoke-website-design" },
    { title: "Business Website", url: "business-website" },
    { title: "Community Website Design", url: "community-website-design" },
    { title: "Custom Blog Design", url: "custom-blog-design" },
    {
      title: "Custom Logo Design Services",
      url: "custom-logo-design-services",
    },
    { title: "Custom Website Design", url: "custom-website-design" },
    { title: "Custom WordPress Website", url: "custom-wordpress-website" },
    {
      title: "Landing Page Design Services",
      url: "landing-page-design-services",
    },
    { title: "Membership Site", url: "membership-site" },
    {
      title: "Personal Websites Design and Development",
      url: "personal-websites-design-and-development",
    },
    { title: "Portfolio Website Design", url: "portfolio-website-design" },
    { title: "Responsive Website", url: "responsive-website" },
    { title: "SAAS Website Design Agency", url: "saas-website-design-agency" },
    {
      title: "Shopify Web Design Services",
      url: "shopify-web-design-services",
    },
    { title: "Website Redesign", url: "website-redesign" },
    { title: "White Label Web Design", url: "white-label-web-design" },
    { title: "WinterCMS Design Services", url: "wintercms-design-services" },
  ],

  "Web Development": [
    {
      title: "Bootstrap Development Services",
      url: "bootstrap-development-services",
    },
    {
      title: "Custom Laravel Web Application",
      url: "custom-laravel-web-application",
    },
    {
      title: "Custom Web Application Development",
      url: "custom-web-application-development",
    },
    {
      title: "Ecommerce Development Services",
      url: "ecommerce-development-services",
    },
    { title: "GatsbyJS Development", url: "gatsbyjs-development" },
    { title: "Laravel", url: "laravel" },
    { title: "Laravel Developer", url: "laravel-developer" },
    { title: "NextJS Website Design", url: "nextjs-website-design" },
    { title: "PSD to WordPress Theme", url: "psd-to-wordpress-theme" },
    { title: "React Development Services", url: "react-development-services" },
    {
      title: "React Native Development Services",
      url: "react-native-development-services",
    },
    { title: "Shopify Custom Development", url: "shopify-custom-development" },
    {
      title: "Shopify Theme Customization Services",
      url: "shopify-theme-customization-services",
    },
    {
      title: "Shopware Development Services",
      url: "shopware-development-services",
    },
    { title: "Shopware Theme Development", url: "shopware-theme-development" },
    {
      title: "TailwindCSS Development Services",
      url: "tailwindcss-development-services",
    },
    {
      title: "Vue JS Development Services",
      url: "vue-js-development-services",
    },
    { title: "Web Development", url: "web-development" },
    { title: "Website As A Service", url: "website-as-a-service" },
  ],

  Ecommerce: [
    { title: "Ecommerce", url: "ecommerce" },
    { title: "Ecommerce Management", url: "ecommerce-management" },
    {
      title: "Ecommerce Website Development",
      url: "ecommerce-website-development",
    },
    { title: "Shopify", url: "shopify" },
    { title: "Shopify Maintenance", url: "shopify-maintenance" },
    { title: "Shopify SEO Services", url: "shopify-seo-services" },
    {
      title: "Shopware Design and Devlopment",
      url: "shopware",
    },
    { title: "Woocommerce", url: "woocommerce" },
  ],

  "SEO & Digital Marketing": [
    { title: "AI Automation Consultancy", url: "ai-automation-consultancy" },
    { title: "Analytics and Reporting", url: "analytics-and-reporting" },
    { title: "Content Marketing Services", url: "content-marketing-services" },
    { title: "Content Writing Services", url: "content-writing-services" },
    {
      title: "Conversion Rate Optimization (CRO)",
      url: "conversion-rate-optimization-cro",
    },
    { title: "Digital Marketing", url: "digital-marketing" },
    { title: "Email Marketing", url: "email-marketing" },
    { title: "Keyword Research", url: "keyword-research" },
    { title: "Local SEO Services", url: "local-seo-services" },
    { title: "Monthly SEO Packages", url: "monthly-seo-packages" },
    { title: "Onsite SEO", url: "onsite-seo" },
    { title: "Search Engine Optimization", url: "search-engine-optimization" },
    { title: "SEO Audit", url: "seo-audit" },
    { title: "Social Media Marketing", url: "social-media-marketing" },
  ],

  "Graphic Design & Color Theory": [
    {
      title: "Freelance Graphic Designer in Mumbai",
      url: "freelance-graphic-designer-in-mumbai",
    },
    { title: "Graphic Design Services", url: "graphic-design-services" },
    {
      title: "Product Images Editing Service",
      url: "product-images-editing-service",
    },
    {
      title: "Social Media Design Services",
      url: "social-media-design-services",
    },
    { title: "UX Design Services", url: "ux-design-services" },
    {
      title: "Mobile App Screen Design Services",
      url: "mobile-app-screen-design-services",
    },
  ],

  "Mobile Solutions": [
    {
      title: "Mobile App Development Services",
      url: "mobile-app-development-services",
    },
    { title: "Mobile Solutions", url: "mobile-solutions" },
  ],

  "Other Services": [
    {
      title: "Freelance Website Developer in Mumbai",
      url: "freelance-website-developer-in-mumbai",
    },
    { title: "Managed Website Services", url: "managed-website-services" },
    {
      title: "News Portal Design and Development",
      url: "news-portal-design-and-development",
    },
    { title: "Pay Monthly Websites", url: "pay-monthly-websites" },
    { title: "Portfolio", url: "portfolio" },
    { title: "Refund Policy", url: "refund-policy" },
    { title: "Website Designer Near Me", url: "website-designer-near-me" },
    { title: "Website Hosting", url: "website-hosting" },
    { title: "Website in a Week", url: "website-in-a-week" },
    { title: "Website Maintenance", url: "website-maintenance" },
    { title: "Website Security", url: "website-security" },
    { title: "Website Solutions", url: "website-solutions" },
    { title: "WordPress", url: "wordpress" },
    { title: "WordPress Customization", url: "wordpress-customization" },
    {
      title: "WordPress Plugin Development",
      url: "wordpress-plugin-development",
    },
    { title: "WordPress Website", url: "wordpress-website" },
    {
      title: "WordPress Website Maintenance",
      url: "wordpress-website-maintenance",
    },
  ],
};


const ServicesList = ({ service = "All", services = {} }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  // Ensure `services` is a valid object
  const filteredServices =
    service === "All"
      ? services
      : services && typeof services === "object" && service in services
        ? { [service]: services[service] }
        : {};

  // Handle edge case: No services available
  if (!services || Object.keys(filteredServices).length === 0) {
    return (
      <section className="bg-neutral-100 py-24">
        <div className="max-w-screen-xl mx-auto px-6 text-center">
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
            Explore Our Comprehensive Services
          </h2>
          <p className="text-gray-600 mb-4">
            Currently, there are no services available to display. Please check
            back later or contact us for more details.
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className="bg-neutral-100 py-24">
      <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row gap-12 px-6">
        {/* Title and Description */}
        <div className="lg:w-1/3">
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
            Explore Our Comprehensive Services
          </h2>
          <p className="text-gray-600">
            From web design to ecommerce, SEO, and mobile solutions, we provide
            a wide range of expert services tailored to meet your business
            needs. Browse through our offerings and find the perfect solution to
            elevate your online presence.
          </p>
        </div>

        {/* Accordion */}
        <div className="lg:w-2/3">
          {Object.keys(filteredServices).map((category, index) => (
            <div
              key={index}
              className={`${
                index % 2 === 0 ? "bg-white" : "bg-neutral-200"
              } border border-gray-300 mb-4 p-4 rounded-lg shadow-md`}
            >
              <button
                className="w-full flex items-center justify-between text-left font-semibold text-neutral-700 py-3 text-xl"
                onClick={() => toggleCategory(category)}
              >
                {category}
                {activeCategory === category ? (
                  <FaChevronUp className="text-gray-600 text-lg" />
                ) : (
                  <FaChevronDown className="text-gray-600 text-lg" />
                )}
              </button>
              <div
                className={`transition-all duration-300 overflow-hidden ${
                  activeCategory === category ? "max-h-screen" : "max-h-0"
                }`}
              >
                <ul className="pl-4 mt-2 text-gray-600">
                  {filteredServices[category].map((service, idx) => (
                    <li key={idx} className="mb-2">
                      <Link
                        href={`/${service.url}`}
                        className="text-neutral-700 hover:text-colorBlue-500 transition text-base"
                      >
                        {service.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesList;

